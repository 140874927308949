import React from 'react'
import tw from 'twin.macro'
import A from '../components/A'
import { H1 } from '../components/Headings'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div tw="min-h-50 pt-32 pb-20 flex flex-col items-center justify-center text-center">
      <H1 css={['font-size: 12rem; line-height: 1em;', tw`font-condensed`]}>
        404
      </H1>
      <p tw="block text-2xl text-gray-500 mb-12">Page Not Found</p>
      <p>
        Oops, looks like you found a bug. 🐞 Maybe try <A to="/">going home?</A>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
